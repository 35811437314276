(function () {
  'use strict';

  /* @ngdoc object
   * @name home.ajuntaments
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments', [
    'ui.router',
    'neo.home.ajuntaments.ajuntament',
    'neo.home.ajuntaments.newAjuntament'
  ]);
}());
